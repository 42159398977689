
import Vue, { PropType } from "vue";
// .pdf,image/*
export default Vue.extend({
  props: {
    accepts: {
      type: Array,
      required: true
    }
  },
  computed: {
    accept(): string {
      return this.accepts.join(",");
    }
  },
  methods: {
    drop(e: any) {
      e.stopPropagation();
      e.preventDefault();

      const files: FileList = e.dataTransfer.files;

      this.$emit("update", files[0]);
    },
    change(e: any) {
      const files: FileList = e.target.files;

      this.$emit("update", files[0]);
    }
  }
});
