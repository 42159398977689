
import Vue, { PropType } from "vue";

import dayjs from "dayjs";

import http from "@/http";

import MyDropBox from "@/components/MyDropBox.vue";
import { Agreement, Breadcrumb } from "@/interfaces";

export interface DataType {
  attachments: File[];
  picker: string;
  menu: boolean;
}

export default Vue.extend({
  components: {
    MyDropBox
  },
  props: {
    agreement: {
      type: Object as PropType<Agreement>,
      required: true
    }
  },
  data(): DataType {
    return {
      attachments: [],
      picker: dayjs()
        .subtract(1, "month")
        .set("date", 1)
        .format("YYYY-MM"),
      menu: false
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "建物",
          disabled: false,
          to: "/buildings"
        },
        {
          text: `${this.agreement.building.name}`,
          disabled: false,
          to: `/building/${this.agreement.building.id}`
        },
        {
          text: "収支報告アップロード",
          disabled: false
        }
      ];
    },
    file(): string {
      if (this.attachments.length === 0) {
        return "";
      }

      return this.attachments[0].name;
    },

    id(): number {
      const { id } = this.$route.params;

      return +id;
    },
    valid(): boolean {
      return this.attachments.length > 0;
    }
  },
  methods: {
    update(file: File) {
      this.attachments = [file];
    },

    async click() {
      const { id } = this.$route.params;

      const url = `agreements/${id}/attachments`;

      let month = this.picker;

      if (/^\d{4}-\d{2}$/.test(this.picker)) {
        month = `${month}`;
      }

      for (const attachment of this.attachments) {
        const formData = new FormData();

        const name = `${id}-${month}.xls`;

        const xls = name.replaceAll("-", "_");

        formData.append("file", attachment, xls);
        formData.append("notice", "true");

        await http.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      }

      this.$router.push(`/agreement/${id}`);
    }
  }
});
