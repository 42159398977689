import { render, staticRenderFns } from "./MyDropBox.vue?vue&type=template&id=57af7f24&scoped=true&"
import script from "./MyDropBox.vue?vue&type=script&lang=ts&"
export * from "./MyDropBox.vue?vue&type=script&lang=ts&"
import style0 from "./MyDropBox.vue?vue&type=style&index=0&id=57af7f24&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57af7f24",
  null
  
)

export default component.exports